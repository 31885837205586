.post {
  border: 0px solid #e1e8ed;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 600px;
  margin: 20px auto;
}

.post-header {
  display: flex;
  align-items: center;
}

.user-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.post-header h3 {
  margin: 0;
  margin-right: 10px;
}

.post-header p {
  margin: 0;
  color: #657786;
}

.post-content p {
  white-space: pre-wrap;
}

.hashtag {
  color: #ff416c;
}

.post-media {
  margin-top: 10px;
}

.post-media img, .post-media video {
  max-width: 100%;
  max-height: 400px;
  border-radius: 8px;
}

.post-stats {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.post-stats button {
  background: none;
  border: none;
  cursor: pointer;
  color: #ff416c;
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-weight: bold;
}

.post-stats button:hover {
  text-decoration: underline;
}

.post-replies {
  margin-top: 10px;
}

.post-replies button {
  background-color: transparent;
  border: none;
  color: #ff416c;
}

.reply {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border-top: 1px solid #e1e8ed;
}

.reply img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.reply-content {
  flex: 1;
}

.reply-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.reply-header h4 {
  margin: 0;
  margin-right: 10px;
  font-size: 14px;
}

.reply-header p {
  margin: 0;
  color: #657786;
  font-size: 12px;
}

.reply-text p {
  margin: 0;
}

.reply-stats {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.reply-stats button {
  background: none;
  border: none;
  cursor: pointer;
  color: #ff416c;
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-weight: bold;
}

.reply-stats button:hover {
  text-decoration: underline;
}

.nested-replies {
  margin-left: 40px;
  border-left: 1px solid #e1e8ed;
  padding-left: 10px;
}

.post-reply-form {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.post-reply-form textarea {
  border: 1px solid #e1e8ed;
  border-radius: 8px;
  padding: 10px;
  resize: none;
  width: 95%;
  margin-bottom: 10px;
}

.post-reply-form button {
  align-self: flex-end;
  background-color: #ff416c;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.post-reply-form button:hover {
  background-color: #ff416c;
}

.user-link {
  text-decoration: none;
  color: black;
}

.reply-reply-form {
  display: flex;
}

.reply-reply-form textarea {
  border: 1px solid #e1e8ed;
  border-radius: 8px;
  padding: 10px;
  resize: none;
  width: 95%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.time-ago {
  margin-left: auto;
  font-size: 0.9em;
  color: #888;
}

.delete-post-button {
  background-color: transparent;
  border: none;
  color: #ff416c;
  font-size: 0.8rem;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .post {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
  }

  .post-header h3 {
    font-size: 1rem;
  }

  .post-header p {
    font-size: 0.9rem;
  }

  .user-img {
    width: 30px;
    height: 30px;
  }

  .post-media img, .post-media video {
    max-height: 300px;
  }

  .post-stats button {
    font-size: 0.9rem;
    margin-right: 10px;
  }

  .reply {
    padding: 5px;
  }

  .reply img {
    width: 25px;
    height: 25px;
  }

  .reply h4 {
    font-size: 0.9rem;
  }

  .reply p {
    font-size: 0.9rem;
  }
}
