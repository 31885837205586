.FlashCard-container {
  font-family: 'Arial', sans-serif;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.FlashCard-title {
  margin: 20px 0;
  font-size: 2rem;
  color: #333;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}

.FlashCard-title span {
  color: #ff416c;
}

.FlashCard-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.FlashCard-select {
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #ffffff;
  cursor: pointer;
  transition: border-color 0.3s;
  max-width: 350px;
}

.FlashCard-button {
  padding: 10px 20px;
  background: linear-gradient(90deg, #ff416c, #ff416c);
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.FlashCard-button:hover {
  background: linear-gradient(90deg, #cf0080, #ff416c);
}

.FlashCard-item-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.FlashCard-results {
  margin-top: 30px;
}

.FlashCard-item {
  perspective: 1000px;
}

.FlashCard-inner {
  width: 300px;
  padding: 0px;
  height: 200px;
  position: relative;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border: 0px solid #ccc;
  border-radius: 10px;
  background: #f9f9f9;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: 500;
}

.FlashCard-inner p {
  padding: 20px;
  font-size: 0.9rem;
}

.FlashCard-item:hover .FlashCard-inner {
  transform: rotateY(180deg);
}

.FlashCard-front, .FlashCard-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.FlashCard-front {
  background-color: #fff;
  color: #333;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  font-size: 1.2rem;
}

.FlashCard-back {
  background-color: #ff416c;
  color: #fff;
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  font-size: 1.2rem;
}

.FlashCard-save {
  margin-top: 10px;
  background: #ff416c;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}
