.simulator-page {
    display: flex;
    height: 100vh;
  }
  
  .left-window {
    width: 10%;
    background-color: #fff;
    color: #333;
    padding: 10px;
  }
  
  .left-window button {
    display: block;
    background-color: transparent;
    border: none;
    color: #333;
    font-size: 1rem;
    cursor: pointer;
    text-align: left;
    margin: 0px 0;
    padding: 18px 10px;
    width: 100%;
    border-radius: 8px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    border-bottom: 1px solid #e8e8e8;
  }
  
  .left-window button:hover {
    background-color: #ff416c;
    color: #fff;
  }
  
  .middle-window {
    width: 50%;
    background-color: #fff;
    color: #333;
    overflow-y: auto;
    padding: 20px;
  }
  
  .right-window {
    width: 40%;
    background-color: #f9f9f9;
    padding: 20px;
    color: #333;
    overflow-y: auto;
    padding: 20px;
    margin: 20px;
    margin-left: 0;
  }
  
  .response-container {
    max-height: 90%;
    overflow-y: auto;
  }
  
  .response {
    background-color: transparent;
    padding: 0px;
    margin-bottom: 0px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
  }

  .right-window h2 {
      font-size: 1.5rem;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 500;
  }

  .response {
    display: flex;
    flex-direction: column;
  }

  
  /* User message styling */
  .cs-user-response {
    background-color: #ff416c; /* Light green background for user messages */
    align-self: flex-end;
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: right;
    padding: 10px;
    border-radius: 15px;
    max-width: 100%;
    word-wrap: break-word;
  }
  
  /* Bot message styling */
  .cs-bot-response {
    background-color: #ffffff; /* White background for bot messages */
    align-self: flex-start;
    width: 80%;
    text-align: left;
    padding: 0px 10px;
    border-radius: 15px;
    margin-top: 0px;
    word-wrap: break-word;
  }

  /* SimulatorPage.css */
@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader .dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #ff416c;
  border-radius: 50%;
  animation: blink 1.4s infinite both;
}

.loader .dot:nth-child(1) {
  animation-delay: 0s;
}

.loader .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loader .dot:nth-child(3) {
  animation-delay: 0.4s;
}

  
  