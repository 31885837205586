/* UploadDashboard.css */

.upload-dashboard {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f7f7f7;
  }
  
  .upload-dashboard  h1 {
    text-align: center;
    margin-top: 50px;
    color: #333;
    font-size: 2.5em;
  }
  
  /* Form styles */
  #upload-form {
    text-align: center;
    margin-top: 30px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  
  #upload-form input[type='file'] {
    display: block;
    margin: 0 auto 20px auto;
  }
  
  #upload-form input[type='submit'] {
    background-color: #09f;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  #upload-form input[type='submit']:hover {
    background-color: #007bff;
  }
  
  /* Spinner styles */
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #09f;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    animation: spin 1s linear infinite;
    display: none;
    margin: 20px auto;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Message styles */
  .processing-message {
    text-align: center;
    font-size: 1.2em;
    color: #666;
    display: none;
  }
  
  /* Container for the spinner and message */
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  /* Button container styles */
  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
    padding-bottom: 50px;
  }
  
  .button-container form {
    margin: 0;
  }
  
  .button-container button {
    background-color: #09f;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-size: 1em;
  }
  
  .button-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .button-container button:not(:disabled):hover {
    background-color: #007bff;
    transform: translateY(-2px);
  }
  
  .file-list {
    list-style-type: none;
    padding: 0;
  }
  
  .file-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    background-color: #fff;
    margin-bottom: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .file-list li span {
    flex-grow: 1;
  }
  
  .file-list li .delete-icon {
    cursor: pointer;
    color: #e74c3c;
  }
  