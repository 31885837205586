.medicoed-contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    background: linear-gradient(90deg, #fff, #fff);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  .medicoed-contact-title {
    text-align: center;
    margin-bottom: 40px;
    color: #111;
    font-size: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
  }
  
  .medicoed-contact-section {
    margin-bottom: 20px;
    text-align: left;
    padding: 2px 40px;
  }
  
  .medicoed-contact-heading {
    color: #333;
    margin-bottom: 10px;
    font-size: 1.5em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
  }
  
  .medicoed-contact-paragraph {
    color: #666;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .medicoed-contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .medicoed-form-group {
    margin-bottom: 15px;
  }
  
  .medicoed-form-label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  .medicoed-form-input,
  .medicoed-form-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .medicoed-form-textarea {
    height: 150px;
  }
  
  .medicoed-form-submit {
    background-color: #ff416c;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .medicoed-form-submit:hover {
    background-color: #e63a61;
  }
  
  @media (max-width: 600px) {
    .medicoed-contact-container {
      padding: 10px;
    }
  
    .medicoed-contact-title {
      font-size: 2em;
    }
  
    .medicoed-contact-heading {
      font-size: 1.3em;
    }
  }