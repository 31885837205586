.community-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: linear-gradient(90deg, #ff416c, #ff416c);
  height: 50px;
  position: fixed;
  min-width: calc(100% - 40px);
  z-index: 1000;
}

.community-logo {
  font-size: 1rem;
  color: white;
  font-weight: 500;
}

.community-nav {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.nav-link {
  color: white;
  text-decoration: none;
  margin-left: 20px;
  font-weight: 500;
}

.nav-link:hover {
  color: #333;
}

.community-cta {
  background: #fff;
  border: none;
  padding: 10px 1rem;
  cursor: pointer;
  color: #ff416c;
  font-weight: bold;
  border-radius: 20px;
  transition: background 0.3s ease;
  margin-left: 10px;
}

.community-cta:hover {
  background: linear-gradient(90deg, #cf0080, #ff416c);
  color: #fff;
}

.mobile-menu {
  display: none;
}

.mobile-menu-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.mobile-dropdown {
  position: absolute;
  top: 50px;
  right: 20px;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.mobile-nav-link {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #111;
}

.mobile-nav-link:hover {
  background: #ff416c;
  color: white;
}

.mobile-cta {
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  color: #ff416c;
  font-weight: bold;
}

@media (max-width: 768px) {

  .community-nav {
    display: none;
  }

  .community-cta {
    display: none;
  }

  .mobile-menu {
    display: block;
  }
}
