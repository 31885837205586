.UploadPapers-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}

.UploadPapers-bigbox {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 60px;
}

.UploadPapers-heading {
  font-size: 28px;
  margin-bottom: 30px;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}

.UploadPapers-form {
  margin-bottom: 20px;
}

.UploadPapers-upload-box {
  border: 0px solid #ddd;
  background-color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}

.UploadPapers-upload-box p {
  padding-left: 20px;
  padding-right: 20px;
}

.UploadPapers-uicon {
  margin-bottom: 10px;
  cursor: pointer;
}

.UploadPapers-uploads-container h3 {
  font-weight: 500;
  background-color: #fff;
  z-index: 10;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 40%;
  width: fit-content;
  margin-top: -15px;
}

.UploadPapers-uploads-container {
  border-top: 1px solid #ddd;
  justify-items: center;
  text-align: center;
}

.UploadPapers-upload-form {
  justify-items: center;
  text-align: center;
  justify-content: center;
}

.UploadPapers-uicon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  border: 1px solid #ddd; /* Adjust dashed border color */
  border-radius: 0px;
}

.UploadPapers-uicon p {
  color: #7e7e7e;
  margin: 0px 0;
  font-size: 0.8rem;
}

.UploadPapers-uicon label {
  font-size: 1.5rem;
}

.UploadPapers-upload-button {
  background-color: #fff;
  color: #555;
  border: 1px solid #ddd;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 20px;
}

.UploadPapers-loader {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.UploadPapers-dot {
  width: 10px;
  height: 10px;
  background-color: #adadad; /* Adjust loader dot color */
  margin: 0 5px;
  border-radius: 50%;
  animation: dot-blink 1.3s infinite ease-in-out;
}

.UploadPapers-bulk-p {
  margin-bottom: 40px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}

@keyframes dot-blink {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.UploadPapers-alert {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.UploadPapers-success {
  background-color: transparent; /* Success alert background color */
  color: #333;
  font-size: 1rem;
}

.UploadPapers-file-list {
  list-style-type: none;
  padding: 0;
}

.UploadPapers-file-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: #fff;
  margin-bottom: 5px;
  border-radius: 5px;
  font-size: 0.9rem;
  margin-left: 80px;
  margin-right: 80px;
}

.UploadPapers-delete-icon {
  color: #dc3545; /* Delete icon color */
  cursor: pointer;
}

.UploadPapers-buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.UploadPapers-button-link button {
  background-color: #ff416c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.UploadPapers-button-link button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

@media (max-width: 768px) {

  .UploadPapers-bigbox {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 60px;
  }

  .UploadPapers-heading {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .UploadPapers-file-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background-color: #fff;
    margin-bottom: 5px;
    border-radius: 5px;
    font-size: 0.9rem;
    margin-left: 0px;
    margin-right: 0px;
    text-align: left;
  }

  .UploadPapers-uploads-container h3 {
    font-weight: 500;
    background-color: #fff;
    z-index: 10;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 18%;
    width: fit-content;
    margin-top: -15px;
  }

  .UploadPapers-button-link button {
    width: 250px;
  }

}

/* UploadPapers.css */
.UploadPapers-bulk-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 16px;
  margin-right: 10px;
}

.UploadPapers-bulk-p {
  color: #7e7e7e;
  font-size: 13px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
