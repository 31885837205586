/* PricingPage.css */

.product-description {
  max-width: 300px; /* Ensures text wraps */
  display: inline-block; /* Prevents full width expansion */
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center; /* Centers text inside */
  margin-top: 10px; /* Adds space above the text */
  font-size: 14px;
  color: #666;
}

.unique-pricing-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.unique-pricing-container {
  padding-bottom: 40px;
  background: #fff;
  color: #333;
  position: relative;
  z-index: 1;
}

.unique-pricing-header {
  text-align: center;
  margin-bottom: 40px;
}

.unique-pricing-header h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.unique-pricing-header p {
  font-size: 1.2em;
  margin-bottom: 30px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.unique-pricing-plans {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.unique-pricing-plan {
  background: #fff;
  color: #333;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 25px;
  width: 300px; /* Increased width for better appearance */
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 800"%3E%3Cdefs%3E%3ClinearGradient id="gradient" x1="0" y1="1" x2="1" y2="0"%3E%3Cstop offset="0%" stop-color="%23ff416c" /%3E%3Cstop offset="100%" stop-color="%23cf0080" /%3E%3C/defs%3E%3Cpath fill="url(%23gradient)" d="M0 0h1200v800H0z" /%3E%3Cpath fill="%23fff" d="M0 0c250 200 250 600 0 600s0-400 600-400 600 200 600 600H0V0z" opacity="0.3"/%3E%3C/svg%3E');
  background-size: cover;
  background-blend-mode: multiply;
}

.unique-pricing-plan::before {
  content: '';
  position: absolute;
  top: -30%;
  left: -30%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  z-index: 0;
  transform: rotate(-20deg);
}

.unique-pricing-plan h2 {
  font-size: 2.2rem;
  font-weight: 500;
  font-family: 'Tahoma', sans-serif;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  margin-top: 0px;
}

.unique-price {
  font-size: 2.5em;
  color: #fff;
  font-weight: 600;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.unique-plan-features {
  list-style-type: none;
  padding: 15px;
  border-radius: 15px;
  font-size: 1.1rem;
  color: #111;
  font-weight: 400;
  position: relative;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0);
  padding-bottom: 10px;
}

.unique-pricing-plan:hover {
  transform: none;
}

.unique-btn-contact {
  background-color: #d7d7d7;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 1em;
  /*cursor: pointer;*/
  transition: background-color 0.3s ease;
  position: relative;
  z-index: 1;
}

.unique-btn-contact:hover {
  /*background: linear-gradient(45deg, #cf0080, #ff416c);*/
  background-color: #d7d7d7;
}

.unique-custom-plan {
  background: #fff;
  border: 2px solid #ff416c;
  box-shadow: none;
  width: 240px;
  border-radius: 5px;
  transition: none;
}

.unique-custom-info {
  font-size: 1.1em;
  padding: 10px;
  color: #777;
  margin-bottom: 20px;
}

.unique-manage-subscription {
  text-align: center;
  margin-bottom: 20px;
}

.unique-manage-subscription-link {
  color: #ff416c;
  text-decoration: none;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.unique-manage-subscription-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.unique-reload-message {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #555;
}

/* Ensure responsiveness */
@media (max-width: 768px) {
  .unique-pricing-plans {
    flex-direction: column;
    align-items: center;
  }

  .unique-pricing-plan, .unique-custom-plan {
    width: 100%;
    max-width: 230px; /* Adjust as necessary */
  }

  .unique-pricing-header h1 {
    font-size: 1.5rem;
  }
}
