.dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'Segoe UI', sans-serif;
}

.sidebar {
  width: 210px;
  background-color: #fff;
  padding: 20px;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px -0.5px 5px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
  height: calc(100vh - 70px);
  position: fixed;
  left: 0;
  top: 70px;
  z-index: 1;
  overflow-y: auto;
  transition: width 0.3s ease;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  width: 100%;
  margin-top: -10px;
}

.sidebar li {
  margin: 20px 0;
  width: 100%;
}

.sidebar a {
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

.sidebar a:hover,
.sidebar a.active {
  background-color: #ff416c;
  color: #fff;
  transform: scale(1.05);
}

.sidebar a .icon {
  margin-right: 10px;
  font-size: 1.5rem;
}

.sidebar a .label {
  flex-grow: 1;
  text-align: left;
}

.main-content {
  flex: 1;
  margin-left: 250px;
  padding: 20px;
  background: linear-gradient(90deg, #fff, #fff);
  color: #2c3e50;
  overflow-y: auto;
  position: relative;
  z-index: 0;
  margin-top: 0px;
  padding-top: 0;
}

.toggle-notes-button {
  position: fixed;
  top: 50%;
  right: 0px; /* Adjust as per your layout */
  transform: translateY(-50%);
  padding: 5px 10px;
  background-color: #ff416c;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Transition transform property */
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight:400;
  font-size: 1rem;
  transform: rotate(270deg);
}

.toggle-notes-button.shifted {
  transform: translateX(-490px) rotate(270deg); /* Move button 500px to the left */
  z-index: 1008;
}

.notes-section {
  width: 400px;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background: white;
  border-left: 1px solid #ddd;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  height: 80%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
}

.toggle-sidebar-button {
  position: fixed;
  top: 60px;
  left: 0px;
  padding: 13px;
  background-color: #fff;
  color: #ff416c;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  border-radius: 0px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px solid #111;
}

.info-text {
  position: fixed;
  top: 45%;
  right: 60px;
  width: 150px;
  font-size: 13px;
  color: #111;
  font-weight: 500;
  animation: fade-in-out 3s forwards;
  background-color: rgb(229, 229, 229);
  padding: 10px;
  border-radius: 8px;
}

.arrow {
  margin-right: 5px;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@media (max-width: 768px) {

  .sidebar {
    top: 110px;
  }

  .collapsed {
    top: 110px;
    width: 40px;
    padding: 5px;
  }

  .sidebar.collapsed .label {
    display: none; /* Hide label text in collapsed state */
  }

  .sidebar.collapsed .icon {
    margin-right: 0;
    display: inline; /* Adjust margin for icon in collapsed state */
  }

  .main-content {
    margin-left: 70px; /* Adjust margin to accommodate collapsed sidebar */
    padding: 0;
  }

  .toggle-notes-button {
    right: 0px; /* Adjusted position for mobile */
    font-size: 0.8rem;
    transform: rotate(270deg);
  }

  .toggle-notes-button.shifted {
    transform: translateX(-290px) rotate(270deg); /* Move button 500px to the left */
    z-index: 1008;
  }
}