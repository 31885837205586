.about-container {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 0;
  margin: auto;
  background: linear-gradient(90deg, #fff, #fff);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-section1 {
  padding: 50px 100px;
  background: linear-gradient(45deg, #cf0080, #ff416c);
  border-bottom: 0px solid #fff;
  box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin: 40px 100px;
  color: #fff;
}

.about-section1 span {
  color: #111;
}

.about-section2 {
  padding: 50px 100px;
  background: linear-gradient(90deg, #fff, #fbfaf0);
  border-bottom: 0px solid #fff;
  box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin: 40px 100px;
}

.about-section2 span {
  color: #ff416c;
}

.about-footer span {
  color: #ff416c;
}

.about-section:last-of-type {
  border-bottom: none;
}

.about-section1 h2 {
  color: #fff;
  font-size: 1.5rem;
  margin-top: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}

.about-section2 h2 {
  color: #111;
  font-size: 1.5rem;
  margin-top: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}

.about-section1 p,
.about-section2 p {
  font-size: 1.1em;
  margin: 10px 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
}

.about-footer p {
  margin: 50px;
  font-size: 1em;
}

@media (max-width: 768px) {
  .about-section1,
  .about-section2 {
      padding: 30px 20px;
      margin: 20px 20px;
  }

  .about-section1 h2,
  .about-section2 h2 {
      font-size: 1.25rem;
  }

  .about-section1 p,
  .about-section2 p {
      font-size: 1em;
  }

  .about-footer p {
      margin: 20px;
      font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .about-section1,
  .about-section2 {
      padding: 20px 15px;
      margin: 10px 10px;
      border-radius: 10px;
  }

  .about-section1 h2,
  .about-section2 h2 {
      font-size: 1rem;
  }

  .about-section1 p,
  .about-section2 p {
      font-size: 0.9em;
  }

  .about-footer p {
      margin: 10px;
      font-size: 0.8em;
  }
}

.team-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0px;
  margin: 50px -50px;
}

.team-member {
  width: calc(25% - 20px); /* 4 members in a row with some gap */
  text-align: center;
  background: transparent;
  color: #fff;
  padding: 0px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.team-photo {
  width: calc(100% - 20px);
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 5px;
  margin: 10px;
}

.team-member h3 {
  margin: 0px 0 5px;
  font-size: 1.2em;
  color: #111;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  padding: 0px 20px;
}

.team-member h4 {
  margin: 0px 0;
  font-size: 1em;
  color: #ff416c;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  padding: 0px 20px;
}

.team-member p {
  font-size: 0.8em;
  color: #555;
  padding: 0px 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-bottom: 10px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .team-member {
    width: calc(33.33% - 20px); /* 3 members in a row */
  }

  .team-container {
    margin: 50px 0px;
  }
}

@media (max-width: 950px) {
  .team-member {
    width: calc(50% - 20px); /* 2 members in a row */
  }

  .team-container {
    margin: 50px 0px;
  }
}

@media (max-width: 600px) {
  .team-member {
    width: 100%; /* 1 member in a row */
  }

  .team-container {
    margin: 50px 0px;
  }
}