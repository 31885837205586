/* ProfileComponent.css */

.profile-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff; /* Use the same background color */
  height: 30px;
  color: #333;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.profile-header-homepage-link {
  text-decoration: none;
  color: #ff416c; /* Use the same primary color */
  font-size: 18px;
  margin-right: 20px;
}

.profile-header-icons {
  display: flex;
  align-items: center;
}

.profile-header-icons button {
  background: linear-gradient(90deg, #ff416c, #ff416c);
    border: none;
    padding: 10px 1rem;
    cursor: pointer;
    color: white;
    font-weight: bold;
    border-radius: 20px;
    transition: background 0.3s ease;
}

.profile-header-icon {
  position: relative;
  margin-right: 20px;
  cursor: pointer;
}

.profile-header-notification-count {
  position: absolute;
  top: -5px;
  right: -10px;
  background: #ff416c; /* Use the same primary color */
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 12px;
}

.profile-header-notifications-dropdown {
  position: absolute;
  top: 30px;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 200px;
}

.profile-header-notification-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.profile-header-notification-item:last-child {
  border-bottom: none;
}

.profile-header-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.profile-header-modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
}

/* ProfileComponent.css */

/* Modal Overlay */
.profile-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is above other content */
}

/* Modal Container */
.profile-modal-container {
  background-color: #fff;
  width: 600px;
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
}

/* Modal Title */
.profile-modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Form Container */
.profile-modal-form {
  display: flex;
  flex-direction: column;

}

/* Form Group */
.profile-modal-form-group {
  margin-bottom: 20px;
}

/* Label */
.profile-modal-label {
  font-weight: 500;
  margin-bottom: 8px;
  color: #333;
  width: fit-content;
}

/* Input Field */
.profile-modal-input {
  padding: 10px;
  border: 0px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  font-size: 1rem;
  outline: none;
  width: 90%;
}

/* Button Container */
.profile-modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Button */
.profile-modal-button {
  padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

/* Save Button */
.profile-modal-save-button {
  background: linear-gradient(90deg, #ff416c, #ff416c);
  color: #fff;
}

/* Edit Button */
.profile-modal-edit-button {
  background: linear-gradient(90deg, #ff416c, #ff416c);
  color: #fff;
}

/* Close Button */
.profile-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  cursor: pointer;
  color: #666;
}

/* Close Button Icon */
.profile-modal-close-button::before {
  content: '×';
  font-size: 1.2rem;
}

/* Close Button Hover */
.profile-modal-close-button:hover {
  color: #333;
}

/* Error Message */
.profile-modal-error {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}

/* Success Message */
.profile-modal-success {
  color: green;
  font-size: 0.875rem;
  margin-top: 5px;
}

/* Styles for settings modal overlay */
.settings-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Styles for settings modal container */
.settings-modal-container {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

/* Styles for settings modal close button */
.settings-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.settings-modal-close-button:hover {
  background-color: #999;
}

/* Styles for settings modal title */
.settings-modal-title {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

/* Styles for settings modal form */
.settings-modal-form {
  display: flex;
  flex-direction: column;
}

/* Styles for settings modal form group (label + input) */
.settings-modal-form-group {
  margin-bottom: 15px;
}

.settings-modal-label {
  font-size: 1rem;
  margin-bottom: 5px;
  display: block;
  color: #333;
}

.settings-modal-input {
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 90%;
}

/* Styles for settings modal buttons */
.settings-modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.settings-modal-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  font-size: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.settings-modal-update-button {
  background-color: #ff416c;
  color: #fff;
}

.settings-modal-close-button {
  background-color: #ccc;
  color: #333;
}

.settings-modal-close-button:hover {
  background-color: #999;
}

.ph-h {
  font-size: 1.5rem;
  font-weight: 400;
}

.profile-modal-tab {
  padding: 10px 20px;
  color: #333;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {

  .profile-header-container {
    padding: 15px;
  }

  .profile-header-homepage-link {
    font-size: 1rem;
  }

  .ph-h {
    font-size: 1rem;
    font-weight: 400;
  }
}
