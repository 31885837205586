.medicoed-privacy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    background: linear-gradient(90deg, #fff, #fff);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  .medicoed-privacy-title {
    text-align: center;
    margin-bottom: 40px;
    color: #111;
    font-size: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
  }
  
  .medicoed-privacy-section {
    margin-bottom: 20px;
    text-align: left;
    padding: 2px 40px;
  }
  
  .medicoed-privacy-heading {
    color: #333;
    margin-bottom: 10px;
    font-size: 1.5em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
  }
  
  .medicoed-privacy-subheading {
    color: #555;
    margin-bottom: 10px;
    font-size: 1.2em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
  }
  
  .medicoed-privacy-paragraph {
    color: #666;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  @media (max-width: 600px) {
    .medicoed-privacy-container {
      padding: 10px;
    }
  
    .medicoed-privacy-title {
      font-size: 2em;
    }
  
    .medicoed-privacy-heading {
      font-size: 1.3em;
    }
  
    .medicoed-privacy-subheading {
      font-size: 1em;
    }
  }
  