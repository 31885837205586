.AIChat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110vh;
  background-color: #fff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.AIChat-heading {
  margin: 20px 0;
  font-size: 2rem;
  color: #333;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}

.AIChat-heading span{
  color: #ff416c;
}

.AIChat-chat-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1200px;
  height: 90vh;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 20px;
}

.AIChat-pastMessages {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f9f9f9;
}

.AIChat-message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  transition: transform 0.2s;
  margin-left: 10px;
  margin-right: 10px;
}

.AIChat-message.user {
  justify-content: flex-end;
  transform: translateX(20px);
}

.AIChat-message.bot {
  justify-content: flex-start;
  transform: translateX(-20px);
}

.AIChat-message-content {
  max-width: 60%;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1rem;
  line-height: 1.5;
}

.AIChat-message.user .AIChat-message-content {
  background-color: #ff416c;
  color: #ffffff;
  text-align: right;
}

.AIChat-message.bot .AIChat-message-content {
  background-color: #e5e5e5;
  color: #000000;
  text-align: left;
}

.AIChat-form-section {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  background: #f7f7f7;
  border-top: 1px solid #e0e0e0;
}

.AIChat-chat-form {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  gap: 10px;
}

.AIChat-file-select,
.AIChat-language-select {
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #ffffff;
  cursor: pointer;
  transition: border-color 0.3s;
  max-width: 250px;
}

.AIChat-file-select:focus {
  border-color: #ff416c;
}

.AIChat-prompt-box {
  flex: 1;
  height: 40px;
  resize: none;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: border-color 0.3s;
  min-width: 200px;
}

.AIChat-prompt-box:focus {
  border-color: #ff416c;
}

.AIChat-button {
  padding: 10px 20px;
    background: linear-gradient(90deg, #ff416c, #ff416c);
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.AIChat-button:hover {
  background: linear-gradient(90deg, #cf0080, #ff416c);
}

/* AIChat.css */

/* Save button styles */
.AIChat-save-button {
  background-color: transparent; /* Green background */
  color: #555; /* White text */
  border: none; /* No border */
  padding: 5px 10px; /* Padding */
  text-align: center; /* Center text */
  text-decoration: none; /* No underline */
  display: inline-block; /* Display as block */
  margin-top: 10px; /* Margin top */
  cursor: pointer; /* Cursor on hover */
  border-radius: 2px; /* Rounded corners */
  margin-left: 10px;
}

/* Popup styles */
.AIChat-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure popup is on top */
  border-radius: 20px;
}

.AIChat-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  width: 300px; /* Width of the popup box */
  text-align: center;
}

.AIChat-popup-content h2 {
  margin-bottom: 20px;
  font-weight: 500;
}

.AIChat-popup-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.AIChat-popup-content button {
  padding: 10px 20px;
    background: linear-gradient(90deg, #ff416c, #ff416c);
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    margin-right: 10px;
}

.AIChat-popup-content button:hover {
  background: linear-gradient(90deg, #cf0080, #ff416c);
}

/* Typing Animation */
.typing-animation {
  display: inline-block;
  margin-left: 10px;
}

.typing-animation .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #bbb;
  margin-right: 5px;
  animation: typing-dot 0.9s infinite ease-in-out;
}

@keyframes typing-dot {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

/* New styles for mobile screens */
@media (max-width: 768px) {

  .AIChat-container {
    margin-top: -30px;
  }

  .AIChat-chat-container {
    margin-top: -10px;
  }

  .AIChat-chat {
    font-size: 1rem;
    margin-left: -30px;
  }

  .AIChat-heading {
    font-size: 1.2rem;
  }

  .AIChat-message-content {
    min-width: 78%; /* Full width for message content */
    font-size: 14px;
  }

  .AIChat-form-section {
    align-items: center; /* Center items horizontally */
    margin-left: -30px;
    flex-wrap: wrap;
  }

  .AIChat-select,
  .AIChat-button {
    flex: 1; /* Equal width for inputs */
    margin-right: 10px; /* Space between inputs */
  }

  .AIChat-button {
    margin-bottom: 0; /* Remove bottom margin */
  }

  .AIChat-prompt-box {
    min-width: 200px;
    height: 30px;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  text-align: left;
}

th, td {
  border: 1px solid #bbbbbb;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}


