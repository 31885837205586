/* src/components/ForgotPassword.css */
.forgot-password-container {
    max-width: 500px;
    margin: 50px auto;
    padding: 30px;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    text-align: center;
    padding-bottom: 40px;
  }
  
  .forgot-password-container h2 {
    margin-bottom: 20px;
    color: #333;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
  }
  
  .forgot-password-container p {
    margin-bottom: 20px;
    color: #666;
  }
  
  .forgot-password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .forgot-password-form label {
    margin-bottom: 10px;
    color: #333;
  }
  
  .forgot-password-form input {
    width: 90%;
    padding: 10px 20px;
    margin-bottom: 20px;
    border: 0px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  
  .forgot-password-form button {
    background: linear-gradient(90deg, #ff416c, #ff416c);
    border: none;
    padding: 10px 1rem;
    cursor: pointer;
    color: white;
    font-weight: 500;
    border-radius: 20px;
    transition: background 0.3s ease;
  }
  
  .forgot-password-form button:hover {
    background: linear-gradient(90deg, #cf0080, #ff416c);
  }
  
  .success-message {
    margin-top: 20px;
    color: green;
  }
  