/* userProfileContainer class for the main container */
.userProfileContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.user-page-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* profileInfo class for the profile information section */
.profileInfo {
  display: flex;
  flex: 1;
  margin: 50px 350px;
  padding: 20px;
  background-color: #fff;
  min-height: 100vh;
  width: 100%;
  width: 800px;
  justify-content: center;
}

/* profilePicture class for the profile picture section */
.profilePicture {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 50%;
  margin-top: 40px;
}

.userPosts {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-left: 350px;
  margin-right: 400px;
  margin-top: -60vh;
}

/* profilePicture img class for the image inside profile picture */
.profilePicture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* userDetails class for the user details section */
.userDetails {
  margin-left: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: fit-content;
  padding: 20px;
  text-align: left;
  border-radius: 20px;
  min-width: 500px;
}

/* userDetails h2 class for the user's name */
.userDetails h2 {
  font-size: 24px;
  margin-bottom: 5px;
  color: #ff416c;
}

/* userDetails p class for other user details */
.userDetails p {
  margin: 5px 0;
  font-size: 16px;
  color: #666;
}

/* userPosts class for the section displaying user posts */


/* errorMessage class for displaying error messages */
.errorMessage {
  color: #f00;
  font-size: 16px;
  margin-top: 10px;
}

.followers-p {
  font-size: 2rem;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.follow-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  background-color: #ff416c;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Styling for the delete button */
.deleteButton {
  background-color: transparent;
  color: rgb(195, 188, 188);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.deleteButton:hover {
  color:rgb(137, 137, 137);
}

/* Styling for the edit popup */
.editPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 600px;
  border-radius: 20px;
}

.editPopup form {
  display: flex;
  flex-direction: column;
}

.editPopup label {
  margin-bottom: 10px;
  margin-right: 10px;
}

.editPopup input {
  padding: 10px 20px;
  margin-bottom: 10px;
  border: 0px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin-left: 20px;
}

.editPopup textarea {
  padding: 10px 20px;
  margin-bottom: -10px;
  border: 0px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin-left: 20px;
}

.editPopup button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  background-color: #ff416c;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.editPopup button[type="submit"] {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  background-color: #ff416c;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.editPopup button[type="button"] {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  background-color: #ff416c;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-b {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  background-color: #ff416c;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bottom-bar {
  display: none;
}

/* Mobile styles */
@media (max-width: 768px) {
  .user-page-content {
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px; /* Adjust for the bottom bar */
  }

  .profileInfo {
    width: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .profilePicture {
    height: 80px;
    width: 80px;
  }

  .profilePicture img {
    height: 80px;
    width: 80px;
  }

  .userDetails {
    min-width: 200px;
    max-width: 240px;
    box-shadow: none;
    margin-left: -5px;
  }

  .editPopup {
    padding: 50px;
    width: 400px;
  }

  .left-sidebar, .right-sidebar {
    display: none; /* Hide sidebars */
  }

  .userPosts {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 0;
    margin-top: -65vh;
  }

  .profileInfo, .userPosts {
    width: 90%;
  }

  .bottom-bar {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px 0;
  }

  .bottom-bar button {
    background: none;
    padding: 5px;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #ff416c;
  }

  .bottom-bar button.active {
    color: #ff416c;
  }
}
