/* src/styles/MainContent.css */
.main-contents {
    margin: 50px 350px;
    padding: 20px;
    background-color: #fff;
    min-height: 100vh;
    width: 100%;
  }
   
  @media (max-width: 768px) {

    .main-contents {
      margin: 10px;
      margin-top: 60px;
      margin-bottom: 50px;
      padding: 0px;
    }

  }
  