/* General Styles */
.MindMapGenerator-container {
  font-family: 'Arial', sans-serif;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.MindMapGenerator-title {
  margin: 20px 0;
  font-size: 2rem;
  color: #333;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}

.MindMapGenerator-title span {
  color: #ff416c;
}

.MindMapGenerator-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.MindMapGenerator-select {
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #ffffff;
  cursor: pointer;
  transition: border-color 0.3s;
  max-width: 350px;
}

.MindMapGenerator-button {
  padding: 10px 20px;
  background: linear-gradient(90deg, #ff416c, #ff416c);
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.MindMapGenerator-button:hover {
  background: linear-gradient(90deg, #cf0080, #ff416c);
}

.MindMapGenerator-image-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.MindMapGenerator-image {
  width: auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  min-height: 500px;
  object-fit: cover;
  object-position: center;
}

.MindMapGenerator-zoom-controls {
  flex: 0 0;
  margin-top: 10px;
}

.MindMapGenerator-zoom-controls button {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 8px;
  margin-right: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.MindMapGenerator-zoom-controls button:hover {
  background-color: #f0f0f0;
}

.MindMapGenerator-zoom-controls button svg {
  color: #333333;
  font-size: 20px;
}

.MindMapGenerator-images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.MindMapGenerator-image-wrapper {
  max-width: 90%;
  margin-bottom: 20px;
}

.MindMapGenerator-image {
  width: 100%;
  object-fit: contain;
}

/* Mobile Styles */
@media (max-width: 768px) {

  .MindMapGenerator-container {
    margin: 0;
    padding: 10px;
  }

  .MindMapGenerator-title {
    font-size: 1.5rem; /* Adjusted for mobile */
  }

  .MindMapGenerator-form {
    flex-direction: column;
    align-items: stretch;
  }

  .MindMapGenerator-select {
    margin: 0 0 10px 0;
    width: 100%;
  }

  .MindMapGenerator-button {
    width: 100%;
    margin-top: 10px;
  }

  .MindMapGenerator-image-wrapper {
    max-width: 100%;
    margin: 0 auto;
  }

  .MindMapGenerator-image {
    width: 100%;
    height: auto; /* Ensures images scale properly */
    max-height: 80vh; /* Limits height on mobile screens */
  }
}
