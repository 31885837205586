.search-page-container {
    margin: 0 auto;
    padding: 0px;
    width: 100%;
  }

.search-main-content {
    flex: 1;
  margin-left: 250px;
  padding: 20px;
  background: linear-gradient(90deg, #fff, #fff);
  overflow-y: auto;
  position: relative;
  z-index: 0;
  margin-top: 0px;
  padding-top: 0;
}

  .search-results-container {
    margin: 50px 50px;
    margin-right: 350px;
    padding: 20px;
  }
  
  .search-bar-container {
    display: flex;
    margin-bottom: 20px;
  }

  .search-profile-info {
      display: flex;
      flex-wrap: wrap;
  }
  
  .search-bar {
    flex: 1;
    padding: 10px 20px;
    font-size: 16px;
    border: 0px solid #ccc;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  
  .search-button {
    padding: 10px 20px;
    margin-left: 15px;
    background-color: #ff416c;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .search-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .search-results {
    margin-top: 20px;
  }
  
  .search-result {
    border: 0px solid #ccc;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .search-profile-info h2 {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 15px;
      font-weight: 500;
      font-size: 1.1rem;
  }

  .search-profile-info p {
      color: #333;
  }

  .search-result img {
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  @media (max-width: 768px) {
    .left-sidebar, .right-sidebar {
        display: none; /* Hide sidebars */
      }

    .search-main-content {
      flex: 1;
      margin-left: -20px;
      padding: 20px;
      background: linear-gradient(90deg, #fff, #fff);
      overflow-y: auto;
      position: relative;
      z-index: 0;
      margin-top: 0px;
      padding-top: 0;
    }

    .search-results-container {
        margin: 30px 0px;
        margin-right: 0px;
        padding: 20px;
      }
  }
  