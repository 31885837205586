.QuestionsGenerator-container {
    font-family: 'Arial', sans-serif;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .QuestionsGenerator-title {
    margin: 20px 0;
  font-size: 2rem;
  color: #333;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  }

  .QuestionsGenerator-title span{
    color: #ff416c;
    }
  
  .QuestionsGenerator-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .QuestionsGenerator-select {
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #ffffff;
    cursor: pointer;
    transition: border-color 0.3s;
    max-width: 350px;
  }
  
  .QuestionsGenerator-button {
    padding: 10px 20px;
    background: linear-gradient(90deg, #ff416c, #ff416c);
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .QuestionsGenerator-button:hover {
    background: linear-gradient(90deg, #cf0080, #ff416c);
  }
  
  .QuestionsGenerator-quiz {
    margin-top: 20px;
  }
  
  .QuestionsGenerator-quiz-item {
    background: #fff;
    padding: 20px;
    margin-bottom: 0px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
  }
  
  .QuestionsGenerator-question {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #333;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    text-align: left;
  }
  
  .QuestionsGenerator-options {
    list-style-type: lower-alpha;
    padding: 0 20px;
  }
  
  .QuestionsGenerator-option {
    background: #fff;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
    text-align: left;
  }
  
  .QuestionsGenerator-option.correct {
    background: #c8e6c9;
    color: #333;
  }
  
  .QuestionsGenerator-option.incorrect {
    background: #fff;
    color: #333;
  }

  .QuestionsGenerator-option.incorrect:hover {
    color: #333;
  }
  
  .QuestionsGenerator-answer {
    margin-top: 10px;
    font-size: 1em;
    color: #555;
    padding: 0 0%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .QuestionsGenerator-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* ensure it's above other content */
  }
  
  .QuestionsGenerator-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 300px;
    width: 100%;
    text-align: center;
  }
  
  .QuestionsGenerator-popup h2 {
    margin-bottom: 10px;
    font-size: 1.2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
  }
  
  .QuestionsGenerator-popup input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .QuestionsGenerator-popup button {
    background-color: #ff416c;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 20px;
    margin-right: 10px;
    font-size: 1rem;
  }
  
  .QuestionsGenerator-popup button:hover {
    background: linear-gradient(90deg, #cf0080, #ff416c);
  }
  
  .QuestionsGenerator-popup button:last-child {
    background-color: #ff416c;
  }

  .QuestionsGenerator-popup button:last-child:hover {
    background-color: linear-gradient(90deg, #cf0080, #ff416c);
  }

  .QuestionsGenerator-save-button {
    background-color: transparent; /* Green background */
  color: #555; /* White text */
  border: none; /* No border */
  padding: 5px 10px; /* Padding */
  text-align: center; /* Center text */
  text-decoration: none; /* No underline */
  display: inline-block; /* Display as block */
  margin-top: 10px; /* Margin top */
  cursor: pointer; /* Cursor on hover */
  border-radius: 2px; /* Rounded corners */
  margin-left: 10px;
  }

/* New styles for mobile screens */
@media (max-width: 768px) {

  .QuestionsGenerator-container {
    margin: 0px;
    margin-left: -10px;
    padding: 10px;
  }

  .QuestionsGenerator-form {
    gap: 10px;
  }

  .QuestionsGenerator-popup {
    left: 20px;
  }

  .QuestionsGenerator-question {
    font-size: 1rem;
  }

  .QuestionsGenerator-title {
    font-size: 1.2rem;
  }

}