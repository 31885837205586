/* src/components/FeaturesSection.css */
.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2rem;
  padding-left: 230px;
  padding-right: 230px;
  padding-top: 100px;
  gap: 3rem; /* Ensures consistent spacing */
  margin-bottom: 80px;
  /*background: linear-gradient(45deg, #cf0080, #ff416c);*/
}

.feature-card {
  flex: 1 1 calc(33% - 5rem); /* Adjust to 3 columns */
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 0rem;
  transition: transform 0.3s, box-shadow 0.3s; /* Add smooth transition for hover effects */
}

.feature-card:hover {
  transform: translateY(-10px); /* Slight lift effect on hover */
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.feature {
  text-align: center;
  padding: 2rem; /* Adjusted padding for better spacing */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none; /* Remove underline for links */
  color: inherit; /* Maintain text color */
}

.feature-icon {
  font-size: 2rem; /* Increased icon size for better visibility */
  color: #ff416c; /* Pink color */
  margin-bottom: 1rem; /* Spacing below the icon */
}

.feature-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-family: 'Tahoma', sans-serif;
  background: linear-gradient(45deg, #ff416c, #ff416c);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.feature-description {
  font-size: 1rem;
  line-height: 1.5;
  font-family: 'Segoe UI', sans-serif;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .features-container {
    padding: 1rem;
    padding-top: 50px;
    gap: 1rem;
    margin-bottom: 40px;
  }

  .feature-card {
    flex: 1 1 100%; /* Stack cards vertically on mobile */
    margin-bottom: 1rem;
    padding: 1.5rem;
  }

  .feature {
    padding: 1rem; /* Reduced padding for mobile */
  }

  .feature-icon {
    font-size: 1.5rem; /* Adjusted icon size for better visibility on mobile */
    margin-bottom: 0.5rem;
  }

  .feature-title {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }

  .feature-description {
    font-size: 0.9rem;
  }
}
