/* CaseStudy.css */

.case-study {
    background-color: #f9f9f9;
    padding: 20px;
    height: calc(100vh - 80px); /* Adjust height considering the fixed header */
    overflow-y: auto; /* Allow vertical scrolling if content overflows */
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0); /* Subtle shadow for depth */
    border-radius: 8px; /* Rounded corners for aesthetics */
  }
  
  /* Styling for content area */
  .case-study > div {
    margin-bottom: 20px;
  }
  
  /* Header styling for each section */
  .case-study h2 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
  }

  .case-study h3 {
    color: #ff416c;
    font-size: 1.2rem;
    margin: 30px 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    text-align: left;
  }

  .case-study h5 {
    color: #333;
    font-size: 1rem;
    margin-left: 10px;
    font-weight: 400;
    text-align: left;
  }
  
  /* Button styling */
  .case-study button {
    background-color: #fff; /* Blue color for buttons */
    color: #333;
    border: none;
    border-radius: 4px;
    padding: 30px 15px;
    border-bottom: 1px solid #eee;
    font-size: 1rem;
    cursor: pointer;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0px 0;
  }
  
  .case-study button:hover {
    background-color: #ff416c; /* Darker blue on hover */
    color: #fff;
  }
  
  /* List styling */
  .case-study ul {
    list-style: none;
    padding: 0;
  }
  
  .case-study li {
    margin-bottom: 0px;
  }
  
  /* Paragraph styling */
  .case-study p {
    font-size: 0.9rem;
    color: #666;
    margin: 5px 0 0;
    font-style: italic;
    text-align: left;
    padding-left: 20px;
  }
  
  /* Styling for collapsed/expanded content */
  .case-study .expanded-content {
    padding-left: 20px; /* Indent for expanded items */
    border-left: 2px solid #ff416c; /* Blue border for expanded content */
  }

  .case-study label {
      display: flex;
      align-items: flex-start;
    font-size: 14px;
    cursor: pointer;
    padding: 20px 40px;
    background-color: #fff;
  }

  .case-study input[type="checkbox"] {
    appearance: none; /* Hide default checkbox */
    width: 18px;
    height: 18px;
    margin-right: 10px;
    border: 2px solid #ccc;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    transform: scale(1.2);
    outline: none;
  }
  
  .case-study input[type="checkbox"]:checked {
    background-color: #ff416c;
    border-color: #ff416c;
  }
  
  .case-study input[type="checkbox"]:checked::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: block;
  }
  
  /* Submit button styling */
  .case-study .submit-button {
    background-color: #fff;
    color: #333;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    display: block;
    width: 100%;
    text-align: center;
  }
  
  .case-study .submit-button:hover {
    background-color: #ff416c;
    color: #fff;
  }

  /* Card styling */
.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center; /* Center cards horizontally */
    margin: 60px 0px;
  }
  
  .card {
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 35%;
    max-width: 250px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
    height: 180px;
  }
  
  .card-icon {
    font-size: 2rem;
    color: #ff416c;
    margin-bottom: 10px;
  }
  
  .card h3 {
    margin: 10px 0;
    font-size: 1.1rem;
    color: #333;
  }

  .mask-icon {
    width: 40px; /* Adjust size as needed */
    display: block;
    margin: 0 auto; /* Center the icon horizontally */
  }

  button:disabled {
    color: #999;
    cursor: not-allowed;
  }

  button:disabled:hover {
    background-color: #fff;
    color: #999;
  }
  
  /* Popup modal styles */
.popup {
  position: fixed;
  top: 0;
  left: 12%;
  width: 46%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 30px;
  padding-bottom: 50px;
  border-radius: 10px;
  text-align: center;
  min-width: 250px;
}

.popup-button button {
  margin-top: 0px;
  padding: 10px 20px;
  background-color: #ff416c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.report-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other content */
}

.report-popup-content {
  background-color: #ffffff; /* White background for the report */
  width: 80vw; /* Responsive width */
  max-height: 90vh; /* Constrain height to avoid overflow */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  overflow-y: auto; /* Enable scrolling when content exceeds max-height */
}

.report-popup-content h3 {
  text-align: center;
  color: #333333;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.report-popup-content div {
  background-color: #f8f8f8; /* Light grey for the preformatted text */
  padding: 15px;
  border-radius: 5px;
  white-space: pre-wrap; /* Wrap long content */
  word-wrap: break-word; /* Prevent overflow on long words */
  max-height: 60vh; /* Max height to ensure scrolling */
  overflow-y: auto; /* Ensure scrolling within the pre block */
  font-size: 1rem;
  line-height: 1.6;
  color: #333333;
  text-align: left;
}

.report-popup-content button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  background-color: #eee;
  color: #333;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.report-popup-content button:hover {
  background-color: #ff416c; /* Slightly lighter on hover */
  color: #fff;
}



  
 