/* ExamPage.css */

.ExamPage-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.ExamPage-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: linear-gradient(90deg, #fff, #fbfaf0);
    min-height: 100vh;
}

.ExamPage-main h1 {
    font-size: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.ExamPage-main span {
    color: #ff416c;
}

.ExamPage-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-width: 600px;
    background-color: transparent;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0);
    transition: all 0.3s ease-in-out;
}

.ExamPage-form label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    color: #495057;
    max-width: 700px;
    font-weight: 500;
    margin-bottom: 10px;
}

.ExamPage-form input,
.ExamPage-form select {
    padding: 12px 15px;
    border: 1px solid #ddd;
    border-radius: 25px;
    margin-top: 5px;
    font-size: 14px;
    color: #333;
    transition: box-shadow 0.3s ease-in-out;
}

.ExamPage-form input:focus {
    box-shadow: 0 0 10px #ff416d50;
    outline: none;
}

.ExamPage-form select:focus {
    box-shadow: 0 0 10px #ff416d50;
    outline: none;
}

.ExamPage-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.ExamPage-form button,
.ExamPage-quiz button {
    padding: 12px 25px;
    background-color: #ff416c;
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    width: fit-content;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.ExamPage-form button:hover,
.ExamPage-quiz button:hover {
    background: linear-gradient(90deg, #cf0080, #ff416c);
    transform: translateY(-2px);
}

.ExamPage-quiz {
    margin-top: 30px;
    width: 100%;
    max-width: 600px;
    background-color: transparent;
    padding: 0px 0px;
    border-radius: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0);
    transition: all 0.3s ease-in-out;
}

.ExamPage-quiz h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 500;
}

.ExamPage-question {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border-radius: 20px;
    transition: background-color 0.3s ease-in-out;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.ExamPage-question p {
    font-weight: bold;
    color: #343a40;
    font-size: 1.1rem;
    margin-bottom: 10px;
}

.ExamPage-option {
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: #495057;
    padding: 10px;
    border-radius: 20px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.ExamPage-option input {
    margin-right: 10px;
    transform: scale(1.2);
}

.ExamPage-option:hover {
    background-color: #f1f1f1;
}

.ExamPage-option input:checked + label {
    background-color: #e9e9e9;
    font-weight: bold;
}

.ExamPage-results {
    margin-top: 30px;
    width: 100%;
    max-width: 600px;
    background-color: #d4edda;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    text-align: center;
}

.ExamPage-results h2 {
    font-size: 1.5rem;
    color: #155724;
    margin-bottom: 10px;
    font-weight: 500;
}

.ExamPage-results p {
    font-size: 1.2rem;
    color: #155724;
}

.ExamPage-correct-answer {
    margin-top: 5px;
  }
  
  .ExamPage-correct-answer.correct {
    color: green;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .ExamPage-correct-answer.incorrect {
    color: red;
    font-weight: 400;
    font-size: 1rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .ExamPage-button-container button:disabled {
    background-color: #ddd; /* Light gray background when disabled */
    cursor: not-allowed;
  }

  .ExamPage-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .ExamPage-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .ExamPage-popup-content h2 {
    margin-top: 0;
    font-weight: 500;
  }
  
  .ExamPage-popup-content label {
    display: block;
    margin-bottom: 10px;
  }
  
  .ExamPage-popup-content input {
    width: 85%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .ExamPage-popup-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .ExamPage-popup-buttons button {
    background-color: #ff416c;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 0.3s ease;
  }
  
  .ExamPage-popup-buttons button:hover {
    background-color: #cf0080;
  }

  .ExamPage-save button {
      background-color: transparent;
      color: #555;
  }
  

@media (max-width: 768px) {
    .ExamPage-main {
        padding: 15px;
    }

    .ExamPage-form, .ExamPage-quiz, .ExamPage-results {
        max-width: 100%;
        padding: 20px;
        box-shadow: none;
    }

    .ExamPage-main h1 {
        font-size: 1.8rem;
    }

    .ExamPage-quiz h2, .ExamPage-results h2 {
        font-size: 1.5rem;
    }

    .ExamPage-form input, .ExamPage-form button, .ExamPage-quiz p, .ExamPage-option, .ExamPage-results p {
        font-size: 14px;
    }
}
