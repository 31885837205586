/* src/components/TestimonialSection.css */
.testimonial-section {
  background: linear-gradient(90deg, #fff, #fbfaf0);
  background-size: cover;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem 2rem;
  color: #111;
  margin: 20px;
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  padding-top: 100px;
  padding-bottom: 100px;
}

.testimonial-heading {
  flex: 1;
  display: flex;
  align-items: flex-start;
}

.testimonial-heading h2 {
  font-size: 4rem;
  color: #111;
  font-family: 'Tahoma', sans-serif;
  font-weight: 500;
  margin: 0;
  text-align: left;
}

.testimonials-container {
  flex: 3;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
}

.testimonial-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  width: 280px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.testimonial-feedback {
  background: linear-gradient(45deg, #cf0080, #ff416c);
  padding: 2rem;
  color: #fff;
  height: 130px;
}

.testimonial-feedback p {
  font-size: 1rem;
  font-style: italic;
}

.testimonial-details {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  justify-content: center;
  text-align: left;
}

.testimonial-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
  border: 3px solid #ff416c;
}

.testimonial-name {
  font-size: 1.2rem;
  font-weight: 500;
  color: #111;
  margin-bottom: -10px;
}

.testimonial-role {
  font-size: 0.8rem;
  color: #ff416c;
  font-weight: 600;
}

.testimonial-heading span {
  color: #ff416c;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .testimonial-section {
    padding-top: 80px; /* Reduced top padding for mobile */
    padding-bottom: 80px; /* Reduced bottom padding for mobile */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
  }

  .testimonial-heading {
    width: 100%; /* Full width for heading */
    text-align: center; /* Center align heading */
    margin-bottom: 2rem; /* Space below heading */
    align-items: center;
  }

  .testimonial-heading h2 {
    font-size: 2.5rem; /* Adjusted font size for smaller screens */
    text-align: center;
  }

  .testimonials-container {
    width: 100%; /* Full width for testimonials */
    justify-content: center; /* Center testimonials horizontally */
    gap: 1rem; /* Reduced gap between testimonials */
  }

  .testimonial-card {
    width: calc(100% - 2rem); /* Adjusted width for spacing on mobile */
  }

  .testimonial-feedback {
    padding: 1.5rem; /* Reduced padding for mobile */
    height: auto; /* Auto height for variable content */
  }

  .testimonial-avatar {
    width: 40px; /* Adjusted avatar size for mobile */
    height: 40px; /* Adjusted avatar size for mobile */
    margin-right: 1rem;
  }

  .testimonial-name {
    font-size: 0.9rem; /* Adjusted font size for mobile */
  }

  .testimonial-role {
    font-size: 0.7rem; /* Adjusted font size for mobile */
  }
}