/* src/App.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

input[type="file"] {
  display: block;
  margin-bottom: 1rem;
}

/* src/App.css */
.logged-in-message {
  color: red;
  text-align: center;
  margin-top: 20px;
  font-size: 1.2em;
  z-index: 2;
}
