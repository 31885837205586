/* src/styles/CommunityPage.css */
.community-page-unique {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.community-page-content-unique {
  display: flex;
}

.right-sidebar-unique.visible {
  display: block !important;
}

.community-page-mobile-unique {
  display: none;
}

/* Mobile styles */
@media (max-width: 768px) {
  .community-page-content-unique {
    display: none;
  }

  .community-page-mobile-unique {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-content-mobile-unique {
    flex: 1;
    width: 100%;
  }

  .tab-container-unique {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border-top: 0px solid #ddd;
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.1);
    justify-content: space-around;
    padding: 10px 0;
  }

  .tab-unique {
    flex: 1;
    text-align: center;
    padding: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    color: #ff416c;
  }

  .tab-unique:hover {
    background-color: #f0f0f0;
  }
}
