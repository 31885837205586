.research-footer {
  background-color: #ff416c;
  color: white;
  padding: 15px 0;
  text-align: center;
}

.research-footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.footer-links-icons {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.footer-link {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-link:hover {
  color: #333333;
}

.footer-social-icons {
  display: flex;
  gap: 10px;
}

.footer-icon {
  color: white;
  font-size: 18px;
  transition: color 0.3s;
}

.footer-icon:hover {
  color: #333333;
}

.footer-separator {
  margin: 0 5px;
}

.research-footer-legal {
  font-size: 14px;
}

@media (max-width: 768px) {
  .research-footer-content {
      flex-direction: column;
      gap: 10px;
      text-align: center;
  }

  .footer-links-icons {
      justify-content: center;
      gap: 15px;
  }

  .footer-separator {
      display: none;
  }

  .research-footer-legal {
      font-size: 12px;
  }
}

@media (max-width: 480px) {
  .footer-link {
      font-size: 14px;
  }

  .footer-icon {
      font-size: 16px;
  }
}
